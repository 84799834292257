<template>
  <b-modal
    :id="id"
    :ref="id"
    modal-class="modal-primary"
    title="ตั้งค่าการเชื่อมต่อ Sandbox"
    hide-footer
    size="lg"
  >
    <validation-observer ref="editRequestForm">
      <div class="mb-2">
        <b-badge
          :variant="requestDetail.environment === 0 ? 'light-warning' : 'light-secondary'"
          class="mr-1"
        >
          <span v-if="requestDetail.environment === 0">
            Sandbox
          </span>
          <span v-else-if="requestDetail.environment ===1">
            Production
          </span>
          <span v-else>
            {{ requestDetail.environment }}
          </span>
        </b-badge>
      </div>
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          v-slot="{ errors }"
          name="callbackUrl"
          rules=""
        >
          <b-form-group
            label="Callback URL"
            label-for="callbackUrl"
          >
            <b-form-input
              id="callbackUrl"
              v-model="formData.callbackUrl"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
        <label for="input-none">Whitelist IP</label>
        <b-row>
          <b-col
            v-for="(ip, index) in whitelist"
            :key="index"
            cols="6"
            class="pb-1"
          >
            <b-form-input
              v-model="whitelist[index]"
              :placeholder="`IP Address ${index + 1}`"
            />
          </b-col>
        </b-row>
        <br>
        <div class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="hideModal"
          >
            ปิด
          </b-button>
          <b-overlay :show="isLoading">
            <b-button
              variant="primary"
              type="submit"
            >
              ยืนยัน
            </b-button>
          </b-overlay>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    requestDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {
        callbackUrl: '',
        whitelistIp: '',
      },
      whitelist: ['', '', '', '', '', ''],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.request.isUpdatingRequest,
    }),
  },
  watch: {
    requestDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['']),
    setData() {
      if (this.requestDetail.id) {
        const data = JSON.parse(JSON.stringify(this.requestDetail))
        this.formData.callbackUrl = data.callbackUrl || ''
        if (data.whitelistIp.length > 0) {
          data.whitelistIp
            .split(',')
            .forEach((ip, index) => (this.whitelist[index] = ip))
        }
      } else {
        this.formData = {
          callbackUrl: '',
          whitelistIp: '',
        }
      }
    },
    hideModal() {
      this.$bvModal.hide(this.id)
    },
    onSubmit() {
      this.$refs.editRequestForm.validate().then(async (success) => {
        if (success) {
          this.formData.whitelistIp = this.whitelist
            .filter((ip) => !!ip)
            .join(',')
          this.$emit('submit', this.formData)
        }
      })
    },
  },
}
</script>
